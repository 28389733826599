import React from 'react'
import './exchange.css'
import Exchange from "../../Assets/Exchange.png"
import {motion} from "framer-motion"
const exchange = () => {
  return (
    <div className="exchange">
      <motion.div className="left-exchange" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }} >
        <div className="left-heading-E">
        Exchange Listing
        </div>
        <div className="left-para-E">
        In accordance with your needs, we may assist you in launching 
        your token on the finest launchpad, but only after some negotiation 
        with exchanges that have a large market cap.
          <div className="blur-eight"></div> 
        </div>
        <div className="left-main-E">
        Negotiate with our manager
        List your token in desire CEX
        </div>

      </motion.div>
      <motion.div className="right-exchange" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}>
        <img src={Exchange} alt="" />
      </motion.div>
      <span className="eighth-blur"></span>
    </div>
  )
}

export default exchange

import React from 'react'
import './agency.css'
import Analyse from "../../Assets/analyse-man.png"
import Bitcoin from "../../Assets/Bitcoin-man.png"
import Thumbsup from "../../Assets/thumbsup-man.png"
import { motion } from "framer-motion"
const agency = () => {

    return (
        <div className="agency">
            <div className="agency-full">
                <motion.div
                    className="agency-one"
                    initial={{ y: 238 }}
                    whileInView={{ y: 10 }}
                    transition={{ type: 'spring', duration: 3 }}
                >
                    <img src={Analyse} alt="" />
                    <h3>Better in analyse stats</h3>

                </motion.div>
                <motion.div
                    className="agency-two"
                    initial={{ y: 238 }}
                    whileInView={{ y: 10 }}
                    transition={{ type: 'spring', duration: 4 }}
                >
                    <img src={Bitcoin} alt="" />
                    <h3>Better in giving service</h3>
                </motion.div>
                <motion.div
                    className="agency-three"
                    initial={{ y: 238 }}
                    whileInView={{ y: 10 }}
                    transition={{ type: 'spring', duration: 3 }}
                >
                    <img src={Thumbsup} alt="" />
                    <h3>Better in quality</h3>
                </motion.div>
            </div>

        </div>
    )
}

export default agency

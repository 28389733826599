import React from 'react'
import './intro.css'
import Working from "../../Assets/Working-man.png"
import { motion } from "framer-motion"

const Intro = () => {

    return (
        
        <div className="intro" >
            <div className="intro-full">
                <div className="intro-left">
                    <div className="intro-head" >Your buisiness to the right people</div>
                    <div className="intro-para">With the assistance of our community through giveaways, airdrops,
                        and freebies, we will support your project launch and development.
                    </div>
                    <div className="apply-btn-1">
                        <motion.button
                        onClick={() => {
                            window.location.href = "https://telegram.dog/Darius0007";
                          }}
                         initial={{ y: 238 }}
                         whileInView={{ y: 10 }}
                         transition={{ type: 'spring', duration: 3 }}
                        >Apply Now</motion.button>
                    </div>
                </div>
                <div className="intro-right">



                    <motion.img
                        src={Working}
                        alt=""
                        className="box"
                        initial={{ y: 238 }}
                        whileInView={{ y: 10 }}
                        transition={{ type: 'spring', duration: 3 }}
                    />


                </div>

            </div>



        </div >
    )
}

export default Intro

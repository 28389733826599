import React from 'react'
import './launchpad.css'
import Launchpad from "../../Assets/Launchpad.png"
import { motion } from 'framer-motion'

const launchpad = () => {
  return (
    <div className="launchpad">
      <div className="left-launchpad">
        <motion.img src={Launchpad} alt="" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}/>
      </div>
      <div className="right-launchpad">
        <motion.div className="right-heading-L" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}>
        Launchpad Listing
        </motion.div>
        <motion.div className="right-para-L" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}>
        With a strong seed round and a strong public round with a strong sale,
         we can assist you in launching your token on the best and top launchpads.
        </motion.div>
        <motion.div className="right-main-L" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}>
        Negotiate with our manager
        Do a fair sale
        Acquire more investors
        </motion.div>

      </div>
      <span className="nineth-blur"></span>
    </div>
  )
}

export default launchpad
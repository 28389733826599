import React from 'react'
import './partner.css'

const partner = () => {
  return (
    <div className="partner" id="partner">
      <div className="partner-full">
        <div className="partner-head">
          Partnership
        </div>
        <div className="partner-para">
          Here is our partnership with web3 projects
        </div>
        <div className="blur-nine"></div>
        <div className="sixth-blur"></div>
      </div>
      
    </div>
  )
}

export default partner
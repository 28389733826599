import React from 'react'
import './partnership.css'
import Partnership from "../../Assets/Partner.png"
import {motion} from "framer-motion"
const partnership = () => {
  return (
    <div className="partnership">
      <div className="left-partnership">
          <motion.img src={Partnership} alt="" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}/>
      </div>
      <motion.div className="right-partnership" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}>
        <div className="right-heading-P">
        Partnership
        </div>
        <div className="right-para-P">
        The easiest technique to gain organic views instead of others is through partnerships.
We can easily move forward after announcing our collaboration on our twitter account.
        <div className="blur-seven"></div> 
        </div>
        <div className="right-main-P">
        Negotiate with our manager
        Start doing announcement
        Acquire more quality
        </div>
         
      </motion.div>
      <div className="blur-seven"></div>
      <div className="seventh-blur"></div>

    </div>
  )
}

export default partnership

import React from 'react'
import './giveaway.css'
import Giveaway from "../../Assets/Giveaway.png"
import { motion } from 'framer-motion'
const giveaway = () => {
  return (
    <div className="giveaway" id="service">
      <div className="left-giveaway" >
        <motion.img src={Giveaway} alt="" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }} />
      </div>
      <motion.div className="right-giveaway" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}>
        <div className="right-heading">
          Hosting Giveaway
        </div>
        <div className="right-para">
          We can Our company may assist you in hosting a contest on Twitter on behalf of Web3 initiatives.
          Our web3 indigenous community members can increase the project's social performance and reach by organising this giveaway.
          <div className="blur-four"></div>
        </div>
        <div className="right-main">
          Negotiate with our manager
          Launch giveaway on social media
          Acquire more audience/community
        </div>

      </motion.div>
      <div className="fourth-blur"></div>

    </div>

  )
}

export default giveaway

import React from 'react'
import './footer.css'
import Logo from "../../Assets/Logo.png"
import Twitter from "../../Assets/Telegram.png"
import Telegram from "../../Assets/Twitter.png"
import Discord from "../../Assets/Discord.png"
import { Link } from 'react-scroll'
const footer = () => {
    return (
        <div className="main-footer">
            <div className="main-2-footer">
                <div className="into-footer">
                    <div className="first-cont">
                        <img src={Logo} alt="" />
                        <span>Web3 Protocol</span>
                    </div>
                    <div className="sec-cont">
                        Web3 protocol is a marketing agency which will help you to get your to buisines
                        seen by real people
                    </div>
                    <div className="third-cont">
                        <button className="btn-2"><Link

                            to='about'
                            span={true}
                            smooth={true}
                        >About Us</Link></button>
                    </div>
                </div>
                <div className="sec-footer">
                  
                    <li><Link

                        to='home'
                        span={true}
                        smooth={true}
                    >Home</Link></li>
                    <li><Link

                        to='about'
                        span={true}
                        smooth={true}
                    >About Us</Link></li>
                    <li><Link

                        to='service'
                        span={true}
                        smooth={true}
                    >Service</Link></li>
                    <li><Link

                        to='partner'
                        span={true}
                        smooth={true}
                    >Partnership</Link></li>
                </div>
                <div className="third-footer">
                    <h2>We accept payment in Crypto</h2>
                    <h2>Follow Us</h2>
                    <div className="Social">
                    <a href="https://twitter.com/Web3_Protocol"><img src={Telegram} alt=""/></a>
                    <a href="https://telegram.dog/Web3_Protocol"><img src={Twitter} alt=""/></a>
                    <a href="https://discord.gg/usC9CsDwKp"><img src={Discord} alt=""/></a>
                    </div>
                </div>
            </div>
            <span className="tenth-blur"></span>
        </div>
    )
}

export default footer
import React from 'react'
import './service.css'
import {motion} from "framer-motion"
const service = () => {
  return (
    <div className="service" id="service">
        <motion.div className="service-full" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}>
            <div className="service-head">
              Services
            </div>
            <div className="service-para">
            Here you can expect the services we provides
            </div>
        </motion.div>
        <div className="blur-three"></div>
        <div className="third-blur"></div>

    </div>
  )
}

export default service
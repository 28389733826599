import './App.css'
import Nav from "./components/Nav/nav"
import Intro from "./components/Intro/intro"
import About from './components/About/about';
import Better from './components/Better/better';
import Agency from './components/Agency/agency';
import Service from './components/Service/service';
import Giveaway from './components/Giveaway/giveaway';
import Ama from './components/Ama/ama';
import Partnership from './components/Partnership/partnership';
import Exchange from './components/Exchange/exchange';
import Launchpad from './components/Launchpad/launchpad';
import Partner from './components/Partner/partner';
import Logos from './components/Logos/logos';
import Footer from './components/Footer/footer';

function App() {
  return (
    <div className="App">
      <header className="App-header">
         <Nav/>
         <Intro/>
         <About/>
         <Better/>
         <Agency/>
         <Service/>
         <Giveaway/>
         <Ama/>
         <Partnership/>
         <Exchange/>
         <Launchpad/>
         <Partner/>
         <Logos/>
         <Footer/>
      </header>
    </div>
  );
}

export default App;

import React from 'react'
import './logos.css'
import Bnb from "../../Assets/Bnb.png"
import Eth from "../../Assets/Eth.png"
import Matic from "../../Assets/Matic.png"
import Solana from "../..//Assets/Sol.png"
import Polka from "../../Assets/Polka.png"
import {motion} from "framer-motion"
const logos = () => {
  return (
   <div className="logos">
    <motion.div className="logo-full" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}>
      <img src={Bnb} alt="" />
      <img src={Eth} alt="" />
      <img src={Matic} alt="" />
      <img src={Solana} alt="" />
      <img src={Polka} alt="" />
    </motion.div>
      
   </div>
  )
}

export default logos
import React from 'react'
import './nav.css'
import Logo from "../../Assets/Logo.png"
import Bars from "../../Assets/Bars.png"
import { useState } from 'react';
import { Link } from "react-scroll"


const Nav = () => {
    const mobile = window.innerWidth <= 768 ? true : false;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="nav" id="home">
            <div className="left-nav">
                <div className="p-name">
                    <img src={Logo} alt="" />
                    <div><span>Web3 Protocol</span></div>
                </div>




                <ul className="n-list">
                    <li><Link

                        to='home'
                        span={true}
                        smooth={true}
                    >Home</Link></li>
                    <li><Link

                        to='about'
                        span={true}
                        smooth={true}
                    >About Us</Link></li>
                    <li><Link

                        to='service'
                        span={true}
                        smooth={true}
                    >Service</Link></li>
                    <li><Link

                        to='partner'
                        span={true}
                        smooth={true}
                    >Partnership</Link></li>
                </ul>

            </div>
            <div className="right-nav">
                <div className="apply-btn">
                    <button onClick={() => {
                            window.location.href = "https://telegram.dog/Darius0007";
                          }}>Apply Now</button>

                </div>
                {isOpen === false && mobile === true ? (
                    <div onClick={() => setIsOpen(true)}>
                        <img className="menu-png" src={Bars} alt="" />
                    </div>
                ) : (
                    <ul className="n-list-1">
                        <li ><Link
                            onClick={() => setIsOpen(false)}
                            to='home'
                            span={true}
                            smooth={true}
                        >Home</Link></li>
                        <li ><Link
                            onClick={() => setIsOpen(false)}
                            to='about'
                            span={true}
                            smooth={true}
                        >About Us</Link></li>
                        <li ><Link
                            onClick={() => setIsOpen(false)}
                            to='service'
                            span={true}
                            smooth={true}
                        >Service</Link></li>
                        <li ><Link
                            onClick={() => setIsOpen(false)}
                            to='partner'
                            span={true}
                            smooth={true}
                        >Partnership</Link></li>
                    </ul>
                )}
            </div>
        </div>
    )
}

export default Nav

import React from 'react'
import './ama.css'
import Ama from "../../Assets/Ama.png"
import { motion } from "framer-motion"
const ama = () => {
  return (
    <div className="ama">
      <motion.div className="left-ama" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }} >
        <div className="left-heading-A">
          AMA Conduct
        </div>
        <div className="left-para-A">
          We'll give you the best AMA service compared to others when we host it on Twitter or in our lively Telegram group.
          It will most definitely be of greater assistance to prospective investors or participants in your enterprise.
          In the future, it will be beneficial if you establish your own token as a web 3 project.
          <div className="blur-six"></div>
        </div>
        <div className="left-main-A">
          Negotiate with our manager
          Start voice/text AMA
          Acquire more investors

        </div>

      </motion.div>
      <motion.div className="right-ama" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}>
        <img src={Ama} alt="" />
      </motion.div>
      <div className="fifth-blur"></div>


    </div>

  )
}

export default ama

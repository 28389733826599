import React from 'react'
import './better.css'
import {motion} from "framer-motion"

const better = () => {
  return (
    <div className="better">
        <motion.div className="better-full" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}>
            <div className="better-head">
              Why we are better than others
            </div>
            <div className="better-para">
            we always give you quality rather than quantity
            </div>
        </motion.div>
        <div className="blur-three"></div>
        <div className="third-blur"></div>

    </div>
  )
}

export default better
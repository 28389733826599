import { motion } from 'framer-motion'
import React from 'react'
import './about.css'
const about = () => {
    return (
        <div className="about" id="about" >
            <motion.div className="about-full" initial={{ y: 238 }}
          whileInView={{ y: 10 }}
          transition={{ type: 'spring', duration: 2 }}> 
                <div className="about-head">
                    About
                </div>
                <div className="about-para">
                    With the cooperation of our community, we will promote the growth of new initiatives by conducting some giveaways and airdrops.
                    Without a doubt, if you want it, we will give you the finest assistance possible while listing your coins on larger exchanges and launchpads.
                </div>
            </motion.div>
            <div className="blur-two"></div>
          <span className="first-blur"></span>
          <span className="sec-blur"></span>
        </div>
    )
}

export default about
